import React, { useState, useEffect } from "react";
import Typical from "react-typical";
import axios from "axios";
import { toast } from "react-toastify";

import SubHeading from "../../Utilities/SubHeading/SubHeading";
import ScrollService from "../../Utilities/ScrollService";
import Animations from "../../Utilities/Animation";

import imgBack from "../../assets/ContactMe/mailz.jpeg";
import loader from "../../assets/ContactMe/download.gif";

import "./ContactMe.css";

const ContactMe = (props) => {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  useEffect(() => {
    // Do whatever you want after first render
    // Put your code here
    Animations.animations.fadeInScreen(props.id);
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [banner, setBanner] = useState("");
  const [formSubmitBool, setFormSubmitBool] = useState(false);

  const handleName = (e) => {
    setName(e.target.value);
    // console.log(name);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    // console.log(email);
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
    // console.log(message);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // console.log("Submitted");
    try {
      let formData = {
        name,
        email,
        message,
      };
      setFormSubmitBool(true);

      const response = await axios.post("/contact", formData);

      if (name.length === 0 || email.length === 0 || message.length === 0) {
        setBanner(response.data.msg);
        toast.error(response.data.msg);
        setFormSubmitBool(false);
      } else if (response.status === 200) {
        setBanner(response.data.msg);
        toast.success(response.data.msg);
        setFormSubmitBool(false);

        // clear filed on the form
        setName("");
        setEmail("");
        setMessage("");
      }
    } catch (error) {
      console.log(error);
        let msg = "Sending Failed. Please shoot an email to toneevin07@gmail.com directly. Thank you for your patience.";
        setBanner(msg);
        toast.error(msg);
        setFormSubmitBool(false);

        setName("");
        setEmail("");
        // setMessage("");
    }
    // console.log(formData);
  };

  return (
    <div className="main-container fade-in" id={props.id || ""}>
      <SubHeading title={"Contact Me"} subHeading={"Get In Touch"} />
      <div className="central-form">
        <div className="col">
          <h2 className="title">
            <Typical loop={Infinity} steps={["Get In Touch 📨", 1000]} />
          </h2>
          <a href="#">
            <i className="fa fa-facebook-square"></i>
          </a>
          <a href="#">
            <i className="fa fa-google-plus-square"></i>
          </a>
          <a href="#">
            <i className="fa fa-instagram"></i>
          </a>
          <a href="#">
            <i className="fa fa-youtube-square"></i>
          </a>
          <a href="#">
            <i className="fa fa-twitter"></i>
          </a>
        </div>
        <div className="back-form">
          <div className="img-back">
            <h4>Send Your Email Here!</h4>
            <img src={imgBack} alt="Image Not Found" />
          </div>
          <form onSubmit={handleFormSubmit}>
            <p>{banner}</p>
            <label htmlFor="name">Name</label>
            <input type="text" value={name} onChange={handleName} />

            <label htmlFor="email">Email</label>
            <input type="email" value={email} onChange={handleEmail} />

            <label htmlFor="message">Message</label>
            <textarea onChange={handleMessage} value={message}>
              {message}
            </textarea>

            <div className="send-btn">
              <button type="submit">
                Send <i className="fa fa-paper-plane"></i>
                {formSubmitBool ? (
                  <b className="load">
                    <img src={loader} alt="loader" />
                  </b>
                ) : (
                  ""
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactMe;
