import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import SubHeading from "../../Utilities/SubHeading/SubHeading";
import ScrollService from "../../Utilities/ScrollService";
import Animations from "../../Utilities/Animation";

import "./Testimonial.css";
import footerImage from "../../assets/Home/shape-bg.png";

const Testimonial = (props) => {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

    useEffect(() => {
        // Do whatever you want after first render
        // Put your code here
        Animations.animations.fadeInScreen(props.id)
     }, [])

  const options = {
    mouseDrag: true,
    touchDrag: true,
    loop: true,
    margin: 0,
    nav: true,
    animateIn: "bounceInRight",
    animateOut: "bounceOutRight",
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <div>
      <SubHeading
        title={"Testimonial"}
        subHeading={"What my client say about me"}
      />
      <section className="testimonial-section fade-in" id={props.id || ""}>
        <div className="container">
          <div className="row">
            <OwlCarousel
              className="owl-carousel"
              id="testimonial-carousel"
              {...options}
            >
              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p>
                      <i className="fa fa-quote-left" />
                      We are really happy to see the development of the Course registration system was timely done. The lay-out was just amazing and we are happy that it will save us a lot of paper work in the future.
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star-half-alt" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                  <div className="client-info">
                    <img src="img/avatar.png" alt="client photo" />
                    <h5>Claudia Bulnes Sánchez</h5>
                    <p>The Hague University, The Netherlands</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p>
                      <i className="fa fa-quote-left" />
                      His work impressed me on multiple levels. I am very happy about the whole experience with him. 
                      He works fast and always helpful with our questions!
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star-half-alt" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                  <div className="client-info">
                    <img src="img/avatar.png" alt="client photo" />
                    <h5>Angie Chelimo</h5>
                    <p>CEO, AngelasPlate</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p>
                      <i className="fa fa-quote-left" />
                      His work is very impressive. He is ever open to suggestions and always quick to deliver. If you need your site developed within a short time, Evin is the guy to look for!
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star-half-alt" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                  <div className="client-info">
                    <img src="img/avatar.png" alt="client photo" />
                    <h5>Florence</h5>
                    <p>Product Owner, DewCIS Solutions</p>
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p>
                      <i className="fa fa-quote-left" />
                      His work is very impressive. He is ever open to
                      suggestions and always quick to deliver. If you need your
                      site developed within a short time, Evin is the guy to
                      look for!
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star-half-alt" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                  <div className="client-info">
                    <img src="img/avatar.png" alt="client photo" />
                    <h5>Stella Mueni</h5>
                    <p>Product Owner, DewCIS Solutions</p>
                  </div>
                </div>
              </div> */}
            </OwlCarousel>
          </div>
        </div>
      </section>

      <div className="footer-image">
        <img src={footerImage} alt="no net" />
      </div>
      
    </div>
  );
};

export default Testimonial;
