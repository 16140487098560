import React, { useState, useEffect } from 'react';
import SubHeading from '../../Utilities/SubHeading/SubHeading';
import ScrollService from '../../Utilities/ScrollService';
import Animations from '../../Utilities/Animation';
import './Resume.css'

function Resume(props) {
    const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
    const [carousalOffSetStyle, setCarousalOffSetStyle] = useState({});

    let fadeInScreenHandler = (screen) => {
        if(screen.fadeInScreen !== props.id)
        return;
        Animations.animations.fadeInScreen(props.id)
    }

    useEffect(() => {
        // Do whatever you want after first render
        // Put your code here
        Animations.animations.fadeInScreen(props.id)
     }, [])

    const fadeInSubscription = ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler)

    const ResumeHeading = props => {

        return (
        <div className='resume-heading'>
            <div className='resume-main-heading'>
                <div className='heading-bullet'>
                </div>
                    <span>{props.heading ? props.heading : ''}</span>
                    {props.fromDate && props.toDate ? (
                        <div className='heading-date'>
                            {props.fromDate + "-" + props.toDate}
                        </div>
                    ) : (
                        <div></div>
                    )}
            </div>
            <div className='resume-sub-heading'>
                <span>{props.subHeading ? props.subHeading : ""}</span>
            </div>
            <div className='resume-heading-description'>
                <span
                    dangerouslySetInnerHTML={{__html: props.description ? props.description : "" }} 
                />
                {/* <span>
                    {props.description ? props.description : ""}
                </span> */}
            </div>
        </div>
        );
    }

    const resumeBullets = [
        {
            label: "Education",
            logoSrc: "education.svg",
        },
        {
            label: "Work History",
            logoSrc: "work-history.svg",
        },
        {
            label: "Programming Skills",
            logoSrc: "programming-skills.svg",
        },
        {
            label: "Projects",
            logoSrc: "projects.svg",
        },
        {
            label: "Interests",
            logoSrc: "interests.svg",
        },
    ]

    const programmingDetails = [
        {skill: "Javascript", ratingPercentage: 90},
        {skill: "ReactJs", ratingPercentage: 85},
        {skill: "JQuery", ratingPercentage: 90},
        {skill: "PHP", ratingPercentage: 90},
        {skill: "Laravel", ratingPercentage: 90},
        {skill: "HTML", ratingPercentage: 90},
        {skill: "CSS", ratingPercentage: 80},
    ]

    const projectDetails = [
        {
            title: "University Systems - DewCIS Solutions", 
            duration: {
                fromDate : "Apr 2016",
                toDate : "Present",
            },
            subHeading:"Technologies Used: Jquery, Java",
            description: "- Built responsive UI for three leading universities in Kenya & Nigeria that provide delightful user experience for students to easily apply for admissions. <br>- Built dashboard graphs for administration using Google Charts library and jQuery for visualization of various student application and registration metrics using Scrum Agile implementation"
        },
        {
            title: "Cloud Human Resource Software - DewCIS Solutions", 
            duration: {
                fromDate : "Apr 2016",
                toDate : "Present",
            },
            subHeading:"Technologies Used: Jquery, Java",
            description: "- Led the Implementation of a dynamic UI customization mechanism for subscribers in the cloud HR software leading to 30% increase in the number of premium subscribers. <br/>- Designed and implemented a customized calendar shift for casual workers that powers HR's software most used feature using Scrum."
        },
        {
            title: "Book Anti-Counterfeit System - University of Nairobi", 
            duration: {
                fromDate : "Apr 2018",
                toDate : "Dec 2021",
            },
            subHeading:"Technologies Used: Hyperledger Composer, Laravel, Android",
            description: "- Implemented book anti-counterfeit software using hyperledger block chain technology using Scrum, agile methodology. Built the software on mobile and web platforms."
        }
    ]

    const resumeDetails = [
        <div className='resume-screen-container' key="education">
            <ResumeHeading 
                heading={'University of Nairobi, Kenya'}
                subHeading={'BACHELOR OF SCIENCE IN COMPUTER SCIENCE'}
                fromDate={'2016'}
                toDate={'2020'}
            />
            <ResumeHeading 
                heading={'FreeCodeCamp'}
                subHeading={'JAVASCRIPT ALGORITHMS $ DATA STRUCTURES'}
                fromDate={'2021'}
                toDate={'2021'}
            />
            <ResumeHeading 
                heading={'Udacity'}
                subHeading={'AWS MACHINE LEARNING CERTIFICATION'}
                fromDate={'2021'}
                toDate={'2021'}
            />
        </div>,

        <div className='resume-screen-container' key="work-experience">
            <ResumeHeading 
                heading={'DewCIS Solutions Limited - Nairobi, Kenya.'}
                subHeading={'SYSTEMS DEVELOPER'}
                fromDate={'Apr 2016'}
                toDate={'Present'}
            />
            <ResumeHeading 
                heading={'Alemobet - Freetown, Sierra Leone'}
                subHeading={'CONSULTANT'}
                fromDate={'Apr 2018'}
                toDate={'Present'}
            />
            <ResumeHeading 
                heading={'Codepamoja - Nairobi, Kenya.'}
                subHeading={'INTERN'}
                fromDate={'Sept 2015'}
                toDate={'Mar 2016'}
            />
            {/* <div className='experience-description'>
                <span className='resume-description-text'>
                    <b>Project:</b> University Systems 
                </span> 
            </div> */}
            {/* <div className='experience-description'>
                <span className='resume-description-text'> 
                    - Built responsive UI for three leading universities in Kenya and Nigeria that provide delightful 
                    user experience for students to easily apply for admissions
                </span> <br />
                <span className='resume-description-text'> 
                    - Built dashboard graphs for administration using Google Charts library and jQuery for 
                    visualization of various student application and registration metrics using Scrum Agile implementation.
                </span>
            </div> */}
            {/* <div className='experience-description'>
                <span className='resume-description-text'>
                    <b>Project:</b> Cloud Human Resource Software 
                </span>
            </div> */}
            {/* <div className='experience-description'>
                <span className='resume-description-text'> 
                    - Led the Implementation of a dynamic UI customization mechanism for subscribers in the cloud HR 
                    software leading to 30% increase in the number of premium subscribers.
                </span> <br />
                <span className='resume-description-text'> 
                    - Designed and implemented a customized calendar shift for casual workers that powers HR's software 
                    most used feature using Scrum.
                </span>
            </div> */}
        </div>,

        <div className='resume-screen-container programming-skills-container' key="programming-skills">
            {programmingDetails.map((skill, index) => (
                <div className='skill-parent' key={index}>
                    <div className='heading-bullet'></div>
                    <span>{skill.skill}</span>
                    <div className='skill-percentage'>
                        <div className='active-percentage-bar' style={{ width: skill.ratingPercentage + "%" }}>

                        </div>
                    </div>
                </div>
            ))}
        </div>,

        <div className='resume-screen-container' key="projects">
            {projectDetails.map((projectDetails, index) => (
                <ResumeHeading
                    key={index}
                    heading={projectDetails.title}
                    subHeading={projectDetails.subHeading}
                    description={projectDetails.description}
                    fromDate={projectDetails.duration.fromDate}
                    toDate={projectDetails.duration.toDate} 
                />
            ))}
        </div>,

        <div className='resume-screen-container' key="interests">
        
                <ResumeHeading
                    heading={'Mentoring'}
                    description={'I mentor junior developers in terms of finding their passion in a particular tech stack and finding best practices to implement them.'}
                />

                <ResumeHeading
                    heading={'Movies'}
                    description={'After a hard working day, I prefer chilling with movies especially tech related, marvel universe or horror movies'}
                />

                <ResumeHeading
                    heading={'Music'}
                    description={'I enjoy listening to music while performing repetitive tasks.'}
                />
            
        </div>
    ]

    const handleCarousal = (index) => {
        let offsetHeight = 360;
        let newCarousalOffset = {
            style: {transform: "translateY("+ index * offsetHeight * -1 + "px)"}
        };

        setCarousalOffSetStyle(newCarousalOffset)
        setSelectedBulletIndex(index)
    }

    const getBullets = () => {
        return resumeBullets.map((bullet, index) => (
            <div onClick={() => handleCarousal(index) }
            className={index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"} key={index}>
                <img className='bullet-logo' src={require(`../../assets/Resume/${bullet.logoSrc}`)} alt={'Icon'} />
                <span className="bullet-label">{bullet.label}</span>
            </div>
        ))
    }

    const renderResume = () => {
        return (
            <div 
            style={carousalOffSetStyle.style} 
            className='resume-details-carousal'
            >
                {resumeDetails.map((ResumeDetail) => ResumeDetail)}
            </div>
        )
    }

    return (
        <div className='resume-container screen-container fade-in' id={props.id || ""}>
            <div className='resume-content'>
                <SubHeading title="Resume" subHeading={'Evin\'s Formal Details'} />
                <div className='resume-card'>
                    <div className='resume-bullets'>
                        <div className='bullet-container'>
                            <div className='bullet-icons'></div>
                            <div className='bullets'>
                                {getBullets()}
                            </div>
                        </div>
                    </div>
                    <div className='resume-bullet-details' >
                        {renderResume()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Resume;
