import React from "react";
import Typical from "react-typical";
import './Profile.css';
import ScrollService from "../../../Utilities/ScrollService";

const Profile = () => {
    return (
        <div className="profile-container">
            <div className="profile-parent">
                <div className="profile-details">
                    <div className="colz">
                        <div className="colz-icon">
                            {/* <a href="#">
                                <i className="fa fa-facebook-square"></i>
                            </a>
                            <a href="#">
                                <i className="fa fa-google-plus-square"></i>
                            </a>
                            <a href="#">
                                <i className="fa fa-instagram"></i>
                            </a>
                            <a href="#">
                                <i className="fa fa-youtube-square"></i>
                            </a> */}
                            <a href="https://www.linkedin.com/in/evingtone-ngoa" target="_blank">
                                <i className="fa fa-linkedin"></i>
                            </a>
                            <a href="https://twitter.com/Evin_Tone" target="_blank">
                                <i className="fa fa-twitter"></i>
                            </a>
                            <a href="https://evin.me.ke" target="_blank">
                                <i className="fa fa-globe"></i>
                            </a>
                        </div>
                    </div>

                    <div className="profile-details-name">
                        <span className="primary-text">
                            {" "}
                            Hello, I'm  
                            <span className="highlighted-text"> Evin</span>
                        </span>
                    </div>
                    <div className="profile-details-role">
                        <span className="primary-text">
                            {" "}
                            <h1>
                                <Typical loop={Infinity}
                                steps={[
                                    "Enthusiastic Developer",
                                    1000,
                                    "Fullstack Developer",
                                    1000,
                                    "React Developer",
                                    1000,
                                    "PHP Developer",
                                    1000,
                                    "Passionate Developer",
                                    1000,
                                ]} 
                                />
                            </h1>
                            <span className="profile-role-tagline">
                                Knack of building efficient applications.
                            </span>
                        </span>
                    </div>
                    <div className="profile-options">
                        <button className="btn primary-btn" onClick={() => ScrollService.scrollHandler.scrollToHireMe()}>
                            Hire Me
                        </button>
                        <a href="Evingtone-Ngoa-Resume.pdf" download='Evingtone-Ngoa-Resume.pdf'>
                            <button className="btn highlighted-btn"> Download Resume </button>
                        </a>
                    </div>
                </div>
                <div className="profile-picture">
                    <div className="profile-picture-background">

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;