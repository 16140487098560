import React, { useEffect } from 'react';
import SubHeading from '../../Utilities/SubHeading/SubHeading';
import ScrollService from '../../Utilities/ScrollService';
import Animations from '../../Utilities/Animation';
import './AboutMe.css';

const AboutMe = (props) => {
    let fadeInScreenHandler = (screen) => {
        if(screen.fadeInScreen !== props.id)
        return;

        Animations.animations.fadeInScreen(props.id)
    }

    useEffect(() => {
        // Do whatever you want after first render
        // Put your code here
        Animations.animations.fadeInScreen(props.id)
     }, [])

    const fadeInSubscription = ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler)

    // console.log("fadeInSubscription", fadeInSubscription);

    const SCREEN_CONSTANTS = {
        description : " I am a Full Stack Developer with vast experience in backend and front-end development along with a knack of building applications with utmost efficiency.",
        highlights: {
            bullets: [
                "Posses over four years of experience in software development.",
                "Proven track record of Rest API Development using Laravel.",
                "Ability to work in teams using GIT version control, Jira/Trello and Scrum",
                "Ability to integrate third party libraries.",
                "Implementation of cloud based large scale projects.",
                "Strong capabilities in grasping any new technologies.",
                "Passionate about doing complex work and willingness to learn new growing technologies.",
                "Adept at formulating and implementing strategies to execute key project deliverables.",
            ],
            heading: "Here are a few highlights: "
        }
    }

    const renderHighlight = () => {
        return SCREEN_CONSTANTS.highlights.bullets.map((value, index) => {
            return (
                <div className='highlight' key={index}>
                    <div className='highlight-blob'></div>
                    <span>{value}</span>
                </div>
            )
        })
    }

    return (
        <div className='about-me-container screen-container fade-in' id={props.id || ""} >
            <div className='about-me-parent'>
                <SubHeading title="About Me" subHeading={'Why Choose Me?'} />
                <div className='about-me-card'>
                    <div className='about-me-profile'></div>
                    <div className='about-me-details'>
                            <span className='about-me-description'>
                                {SCREEN_CONSTANTS.description}
                            </span>
                            <div className='about-me-highlights'>
                                <div className='highlight-heading'>
                                    <span>{SCREEN_CONSTANTS.highlights.heading}</span>
                                </div>
                                {renderHighlight()}
                            </div>
                            <div className='about-me-options'>
                                <button className="btn primary-btn" onClick={() => ScrollService.scrollHandler.scrollToHireMe()}>
                                    Hire Me
                                </button>
                                <a href="Evingtone-Ngoa-Resume.pdf" download='Evingtone-Ngoa-Resume.pdf'>
                                    <button className="btn highlighted-btn"> Get Resume </button>
                                </a>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    );
};

export default AboutMe;
